import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contact_details_activity_navigation = _resolveComponent("contact-details-activity-navigation")!
  const _component_contact_details_activities_list = _resolveComponent("contact-details-activities-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_contact_details_activity_navigation, {
      search: _ctx.search,
      "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
      filters: _ctx.filters,
      "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters) = $event)),
      activities: _ctx.activities,
      "filters-list": _ctx.filtersList,
      onToggleExpand: _ctx.toggleExpand
    }, null, 8, ["search", "filters", "activities", "filters-list", "onToggleExpand"]),
    _createVNode(_component_contact_details_activities_list, {
      ref: "activitiesList",
      search: _ctx.search,
      activities: _ctx.organizationActivitiesValue,
      "onUpdate:activities": _cache[2] || (_cache[2] = ($event: any) => (_ctx.organizationActivitiesValue = $event))
    }, null, 8, ["search", "activities"])
  ]))
}