import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_filter_checkbox = _resolveComponent("dropdown-filter-checkbox")!
  const _component_dropdown_filter_radio = _resolveComponent("dropdown-filter-radio")!
  const _component_dropdown_filter_assignee = _resolveComponent("dropdown-filter-assignee")!
  const _component_contact_details_navigation_container = _resolveComponent("contact-details-navigation-container")!

  return (_openBlock(), _createBlock(_component_contact_details_navigation_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_dropdown_filter_checkbox, {
        modelValue: _ctx.filters.activities.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.activities.value) = $event)),
        options: _ctx.filters.activities.options,
        icon: "filter_alt",
        "option-label": "name",
        "option-key": "name",
        "all-text": "All activities",
        "btn-size": "default",
        "button-class": "font-weight-medium"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        modelValue: _ctx.filters.dateCreated.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.dateCreated.value) = $event))
      }, _ctx.filters.dateCreated, {
        icon: "event",
        "all-text": "All time",
        "btn-size": "default",
        "button-class": "font-weight-medium"
      }), null, 16, ["modelValue"]),
      _createVNode(_component_dropdown_filter_assignee, {
        modelValue: _ctx.filters.assignee.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.assignee.value) = $event)),
        options: _ctx.filters.assignee.options,
        icon: "supervisor_account",
        "search-placeholder": "Search user",
        "all-text": "All users",
        "btn-size": "default",
        "button-class": "font-weight-medium"
      }, null, 8, ["modelValue", "options"])
    ]),
    _: 1
  }))
}